import { createGlobalStyle } from 'styled-components';

import theme from './theme';

export default createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Noto+Serif+TC:wght@400;700&display=swap');
  body {
    font-family: ${theme.font};
    min-width: 100%;
    min-height: 100%;
  }

  .real.shape {
    fill: ${theme.colors.primary};
    fill-opacity: 0.8;
  }

  .want.shape {
    fill: none;
    stroke: ${theme.colors.primary};
  }
`;
